exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-career-form-index-jsx": () => import("./../../../src/pages/career-form/index.jsx" /* webpackChunkName: "component---src-pages-career-form-index-jsx" */),
  "component---src-pages-career-form-thank-you-jsx": () => import("./../../../src/pages/career-form/thank-you.jsx" /* webpackChunkName: "component---src-pages-career-form-thank-you-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-case-studies-index-jsx": () => import("./../../../src/pages/case-studies/index.jsx" /* webpackChunkName: "component---src-pages-case-studies-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-contact-thank-you-jsx": () => import("./../../../src/pages/contact/thank-you.jsx" /* webpackChunkName: "component---src-pages-contact-thank-you-jsx" */),
  "component---src-pages-glossary-index-jsx": () => import("./../../../src/pages/glossary/index.jsx" /* webpackChunkName: "component---src-pages-glossary-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-webinar-thank-you-for-registering-jsx": () => import("./../../../src/pages/webinar/thank-you-for-registering.jsx" /* webpackChunkName: "component---src-pages-webinar-thank-you-for-registering-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/BlogCategory.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-detail-jsx": () => import("./../../../src/templates/BlogDetail.jsx" /* webpackChunkName: "component---src-templates-blog-detail-jsx" */),
  "component---src-templates-blog-tag-jsx": () => import("./../../../src/templates/BlogTag.jsx" /* webpackChunkName: "component---src-templates-blog-tag-jsx" */),
  "component---src-templates-case-study-detail-jsx": () => import("./../../../src/templates/CaseStudyDetail.jsx" /* webpackChunkName: "component---src-templates-case-study-detail-jsx" */),
  "component---src-templates-fabric-jsx": () => import("./../../../src/templates/Fabric.jsx" /* webpackChunkName: "component---src-templates-fabric-jsx" */),
  "component---src-templates-glossary-detail-jsx": () => import("./../../../src/templates/GlossaryDetail.jsx" /* webpackChunkName: "component---src-templates-glossary-detail-jsx" */),
  "component---src-templates-powerbi-jsx": () => import("./../../../src/templates/Powerbi.jsx" /* webpackChunkName: "component---src-templates-powerbi-jsx" */),
  "component---src-templates-service-detail-jsx": () => import("./../../../src/templates/ServiceDetail.jsx" /* webpackChunkName: "component---src-templates-service-detail-jsx" */),
  "component---src-templates-sub-service-detail-jsx": () => import("./../../../src/templates/SubServiceDetail.jsx" /* webpackChunkName: "component---src-templates-sub-service-detail-jsx" */),
  "component---src-templates-webinar-detail-jsx": () => import("./../../../src/templates/WebinarDetail.jsx" /* webpackChunkName: "component---src-templates-webinar-detail-jsx" */)
}

